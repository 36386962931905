/***************** show *********************/
.question-panel{
    margin-top: -22px;
    width: 100%;
    background: #fff;
    margin-bottom: 22px;
}
.question-panel .question-wrapper{
    position: relative;
    padding: 20px 0;
    overflow: hidden;
    background: #fff;
    border-bottom: 1px solid #e7eaf1;
    box-shadow: 0 1px 3px 0 rgba(0,37,55,.05);
}
.question-panel-main{
    padding: 0 24px;
    box-sizing: border-box;
}
.question-panel-main .question-panel-topics{
    padding: 1px 0;
    margin-right: -2px;
    margin-left: -2px;
    display: block;
}
.question-panel-main .question-panel-title{
    margin-top: 4px;
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #1e1e1e;
    height: auto;  
    word-wrap:break-word;  
    word-break:break-all;  
    overflow: hidden;

}
.question-panel-main .question-panel-detail{
    font-size: 18px;
    line-height: 27px;
    display: table;
    /* width:200px; */
    /* height:800px; */
    height: auto;  
    word-wrap:break-word;  
    word-break:break-all;  
    overflow: hidden;
    /* display: table; */

    
    
}
.question-panel-detail img{
    max-width: 100%;
}
.question-panel-main .question-panel-footer{
    padding-bottom: 12px;
    margin-top: 8px;
    margin-bottom: -12px;
    background: #fff;
}
.question-panel-footer.question-panel-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    height: 100%;
    margin: 0 auto;
}
.panel-heading .header-body {
    color: #111;
    font-size: 18px;
    font-weight: bold;
}
a.topic{
    font-weight:bold;
    font-size: 15px;
}
.question-follow{
    margin-bottom: 18px;
}
.question-follow>h2{
    margin-bottom: 5px;
}

.actions {
    display: block !important;
}
.action-buttons {
    float: right;
}
.action-btn {
    display: inline-block !important;
    box-sizing: border-box !important;
    float: right;
}

.answer-info-panel .answer-content {
    display: table-cell;
    font-size: 16px;
    font-weight: 700;
}
.answer-info-avatar{
    width: 3.0em;
    height:3.0em;
    display: table-cell;
    padding-right: 1em;
}
.answer-info-panel .answer-info-avatar img{
    width: 3.0em;
    height:3.0em;
    border-radius: 50%;
}
.media-body .answer-info-avatar img{
    width: 3.0em;
    height:3.0em;
    border-radius: 50%;
}
.answer-item-name{
    display: table-cell;
}

.panel-body .answer-item{
    border-bottom: 1px solid #f0f2f7;
    padding: 10px;
    margin-top: 0 !important;
}
.panel-body .media:last-child{
    border: none;
}
.answer-item .answer-item-content{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;

}
.answer-item .answer-item-time{
    margin-top: 0.8em;
    margin-bottom: 0.2em;
    font-size: 14px;
    color: #8590a6;
}
.answer-item .answer-item-actions{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: #8590a6;
}
.answer-item-action{
    margin-left: 20px;
    display: inline-block;
    color: #8590a6;
}
#commentForm .is-naked{
    font-size: 14px;
    color: #8590a6;
    height: 100%;
    margin-right: 10px;
}
.answer-item-actions #commentForm .is-naked {
    font-size: 14px;
    color: #8590a6;
    height: 100%;
    margin-right: 10px;
    margin-left: 24px;
}
.media .user-statics{
    margin-bottom: 10px;
}
.about-question{
    text-align: center;
    margin-bottom: 20px;
}
.about-question>h2{
    margin-bottom: 4px;
}
.question-empty-panel{
    padding:20px !important;
    font-size: 24px;
    text-align: center;
    color: #aaa;
}
.question-like{
    cursor: pointer;
}
.question-like.active{
    color: #F2711C;
}
.question-action-btns .action-button{
    display: inline-table;
}
.question-action-btns .action-button:last-child{
    float: right;
}
/***************** index *********************/
.feed-list{

}
.feed-item{
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}
.feed-item .feed-avatar,.feed-avatar img{
    display: block;
    width: 38px;
    height: 38px;
}
.feed-item .feed-avatar{
    float: left;
    margin: 3px 0 0;
    position: relative;
}
.feed-avatar .top-question{
    position: absolute;
    top:-8px;
    right: -6px;
    padding: 5px 7px !important;
}
.feed-item .feed-avatar img{
    border-radius: 3%;
}
.feed-main{
    margin-left: 48px;
}
.feed-item .feed-content h4 {
    margin-right: 20px;
}
.feed-item .feed-author{
    margin:6px 0;
}
.feed-item .feed-author>span{
    color: #00989a;
    font-weight: bold;
    margin-right: 8px;
}
.feed-main .feed-item-body{
    position: relative;
    margin-top: 8px;
    display: block;
}
.feed-meta{
    display: block;
}
.feed-meta .feed-meta-panel{
    padding: 5px 0;
}
.feed-meta .feed-meta-panel .meta-item{
    color: #8590a6;
    vertical-align: middle;
    margin-right: 18px;
}
