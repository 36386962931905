.panel-body.content img {
    width: 100%;
}

a.topic{
    background: #eff6fa;
    padding: 1px 10px 0;
    border-radius: 30px;
    text-decoration: none;
    margin: 0 5px 5px 0;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
}

a.topic:hover{
    background: #259;
    color: #fff;
    text-decoration: none;
}

.button.is-naked{
    background: 0 0;
    border: none;
    border-radius: 0;
    padding: 0;
    height:auto;
}
.actions{
    display: flex;
    padding: 0px 20px;
}
.delete-form{
    margin-left: 20px;
}
.delete-button{
    color: #3097D1;
    text-decoration: none;
}

.about-author{
    text-align: center;
}

.user-statics ,.user-actions{
    margin-top:10px;
}

.user-statics{
    display: flex;
}
.statics-item{
    padding: 2px 20px;
}

.message-list{
    margin-top:80px;
}